import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { CustomModal } from './CustomModal';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { modifyPin } from '../../services/authService';
import { useDispatch } from 'react-redux';
import { setPin } from '../../store/authActions';
import { PinInput } from '../PinInput';
import Lock from '../../Lock.svg';
import { closeAddPinModal } from '../../store/modalActions';
import { setSessionCookie } from '../../services/cookieService';
 
import strings  from '../../config/strings';
import { OptionalPinModal } from './OptionalPinModal';
const { modals : { addPin } } = strings.UI;

const useStyles = makeStyles(() => {
    return {
        disclamer: {
            marginTop: '40px',
        },
        button: {
            margin: '40px',
        },
        img: {
            width: '100%',
        },
    };
});

const pinFieldInitialState = {
    pin: '',
    showPin: false,
};

const errorsInitialState = { newPinError: '' };

export const AddPinModal = ({ isOpen }: { isOpen : boolean }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [newPin, setNewPin] = useState(pinFieldInitialState);
    const [isModalDisabled, setIsModalDisabled] = useState(false);
    const [errors, setErrors] = useState(errorsInitialState);
    const [openAddPinModal, setAddPinModal] = useState(false);

    const validatePin = () => {
        let isValid = true;
        let newErrors = errorsInitialState;
        if (newPin.pin.length < 4) {
            newErrors = { ...newErrors, newPinError: 'Must contain 4 numbers' };
            isValid = false;
        }
        setErrors(newErrors);
        return isValid;
    };

    const handlePinSubmit = (e : React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const token = localStorage.getItem('private_token');
        if (token) {
            if (validatePin()) {
                setIsModalDisabled(true);
                modifyPin(token, null, newPin.pin)
                    .then(() => {
                        dispatch(setPin(newPin.pin));
                        setSessionCookie('pin', newPin.pin);
                        dispatch(closeAddPinModal());
                        navigate('/');
                    })
                    .catch((err) => {
                        console.log('[ERROR]', err);
                    });
            }
        }
    };

    const handleCancel = () => {
        setAddPinModal(false);
    }

    return (
        <>
            <OptionalPinModal isOpen={isOpen} setOpen={setAddPinModal}/>
            <CustomModal isOpen={openAddPinModal} onClose={handleCancel}>
                <form>
                <Box display="flex" justifyContent="space-around">
                    <Box
                        width="60px"
                        display="flex"
                        justifyContent="center"
                        alignContent="center"
                        padding="10px"
                        borderRadius="50%"
                        bgcolor="#8EC1E3"
                        marginBottom="16px"
                    >
                        <img src={Lock} className={classes.img} />
                    </Box>
                </Box>
                <Typography
                    marginBottom="20px"
                    align="center"
                    fontWeight="bold"
                    id="modal-modal-title"
                    variant="h3"
                    component="h2"
                >
                    {addPin.title}
                </Typography>
                <Typography align="center" component={'div'}>
                    <PinInput
                        setValues={setNewPin}
                        isModalDisabled={isModalDisabled}
                        values={newPin}
                        label={addPin.fields.newPin}
                        errorText={errors.newPinError}
                        autofocus
                    />
                    <br />
                    <Typography marginTop="1px" align="left" fontSize="12px" variant="caption">
                        {addPin.legend}
                    </Typography>
                </Typography>

                <Typography marginTop="40px" align="right">
                    <Button
                        disabled={isModalDisabled}
                        sx={{ marginRight: '16px' }}
                        className={classes.button}
                        color="primary"
                        variant="text"
                        onClick={handleCancel}
                    >
                        {addPin.buttons.cancel}
                    </Button>
                    <Button
                        disabled={isModalDisabled}
                        className={classes.button}
                        color="primary"
                        variant="contained"
                        onClick={handlePinSubmit}
                        type="submit"
                    >
                        {addPin.buttons.confirm}
                    </Button>
                </Typography>
                </form>
            </CustomModal>
        </>
    );
};
