import React from 'react';
import { Button, Typography } from '@mui/material';
import { CustomModal } from './CustomModal';
import { makeStyles } from '@mui/styles';

import strings  from '../../config/strings';
import { ProviderSetting } from '../../types';
const { modals : { welcome : { greetings } } } = strings.UI;

const useStyles = makeStyles(() => {
    return {
        disclamer: {
            marginTop: '40px',
            textAlign: 'center'
        },
        button: {
            margin: '40px',
        },
    };
});

interface Props {
    isOpen: boolean;
    setShowWelcomeModal: React.Dispatch<React.SetStateAction<boolean>>;
    providerSettings: ProviderSetting | null;
}

export const WelcomeModal = ({ isOpen, setShowWelcomeModal, providerSettings }: Props) => {
    const classes = useStyles();
    return (
        <CustomModal isOpen={isOpen}>
            <form>
            <Typography
                marginBottom="20px"
                align="center"
                fontWeight="bold"
                id="modal-modal-title"
                variant="h3"
                component="h2"
            >
                {greetings.title}
            </Typography>
            <Typography
                fontWeight="normal"
                align="center"
                variant="subtitle1"
                id="modal-modal-description"
                sx={{ mt: 5, fontSize: "1.1rem", color: "#721c24" }}
            >
                {greetings.body}
            </Typography>
            <Typography
                fontWeight="normal"
                align="center"
                variant="subtitle1"
                id="modal-modal-description"
                sx={{ mt: 5, fontSize: "1.1rem", color: "#721c24" }}
            >
                {greetings.warning}
            </Typography>
            <Typography marginTop="20px" align="right">
                <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    onClick={(e : React.FormEvent<HTMLButtonElement>) => {
                        e.preventDefault();
                        setShowWelcomeModal(false);
                        localStorage.setItem('first_time', 'false')
                    }}
                >
                    {greetings.buttons.confirm}
                </Button>
            </Typography>
            </form>
        </CustomModal>
    );
};
