import React from 'react'
import { Box, Link, Typography } from '@mui/material'
import strings from '../config/strings'
import { TermsProps } from '../types'

export const Terms = ({ setShowInsuranceTermsModal, tac }: TermsProps) => {
  const handleDownload = () => {
    if(tac){
      const byteArray = new Uint8Array(Object.values(tac));

      const pdfBlob = new Blob([byteArray], { type: 'application/pdf' });
  
      const link = document.createElement('a');
      link.href = URL.createObjectURL(pdfBlob);
      link.download = 'Terms_Conditions.pdf';
      link.click();
      URL.revokeObjectURL(link.href);
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="body2">
        {strings.UI.modals.insuranceTerms.linkText.text}
      </Typography>
      <Box sx={{ mt: 0.25 }}>
        <Typography variant="body2">
          Please, <Link onClick={() => setShowInsuranceTermsModal(true)}>read the summary of exclusions</Link> for a quick breakdown.
        </Typography>
      </Box>
      <Box sx={{ mt: 0.25 }}>
        <Typography variant="body2">
          <Link onClick={() => handleDownload()}>Read the full terms and conditions here.</Link>
        </Typography>
      </Box>
    </Box>
  )
}
