import { ProviderSetting } from "../types";

export const SET_PIN = "SET_PIN";
export const SET_PROGRAM_NAME = "SET_PROGRAM_NAME";
export const SET_DECLARATION_TYPE = "SET_DECLARATION_TYPE";
export const SET_READ_ONLY = "SET_READ_ONLY";
export const SET_COVERAGE_LIMIT_LOCKED = "SET_COVERAGE_LOCKED";
export const SET_PROVIDER_SETTINGS = "SET_PROVIDER_SETTINGS";
export interface AuthStatusType{
    pin: string|null;
    programName: string|null;
    declarationType: string|null;
    readOnly: boolean;
    coverageLimitLocked: boolean;
    providerSettings: ProviderSetting | null;
};