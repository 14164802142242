import { Dispatch, SetStateAction } from 'react';

type appConfig = {
    REACT_APP_API_URL: string;
}
declare global {
    interface Window { appConfig: appConfig; }
}

export interface EditItemProps {
    showEditItem: boolean;
    setShowEditItem: Dispatch<SetStateAction<boolean>>;
    categories: Category[];
    initItem: null | NewItem;
    coverage: Coverage | null;
    inventory: Item[];
}

export interface AddItemProps {
    addItem: boolean;
    setAddItem: Dispatch<SetStateAction<boolean>>;
    initItem: NewItem | null;
    categories: Category[];
    coverage: Coverage | null;
    inventory: Item[];
}

export interface CategoryRowProps {
    row: CategoryWithInventory;
    setAddItem: Dispatch<SetStateAction<boolean>>;
    setInitItem: Dispatch<SetStateAction<null | NewItem>>;
    setShowEditItem: Dispatch<SetStateAction<boolean>>;
    setIsRowOpen: Dispatch<SetStateAction<boolean>>
}

export interface CategoriesTableProps {
    coverage: Coverage | null,
    items: Item[];
    categories: CategoryWithInventory[];
    setAddItem: Dispatch<SetStateAction<boolean>>;
    setShowInfoModal: Dispatch<SetStateAction<boolean>>;
    setInitItem: Dispatch<SetStateAction<null | NewItem>>;
    setShowEditItem: Dispatch<SetStateAction<boolean>>;
    pastEffectiveDateError: string;
    setIsRowOpen: Dispatch<SetStateAction<boolean>>;
}

interface PinState {
    pin: string;
    showPin: boolean;
}

export interface PinInputProps {
    setValues: Dispatch<SetStateAction<PinState>>;
    isModalDisabled: boolean;
    values: PinState;
    label: string;
    errorText: string;
    autofocus?: boolean;
}

export interface TermsProps {
    setShowInsuranceTermsModal: Dispatch<SetStateAction<boolean>>;
    tac: Buffer | null;
}
export interface InsuranceModalProps extends Omit<TermsProps, 'tac'>{
    isOpen: boolean;  
}
export interface Category {
    id: string;    
    name: string;    
    deleted: boolean;    
    order: number;    
    shortName: string;    
    detail?: string;
    isDisabled?: boolean;
}

export interface Item {
    id: string;    
    name: string;    
    description: string;
    category: Category["id"];    
    cubicFeet: number;
    quantity: number;
    valuePerItem: number;
    createdOn?: string; 
    lastModified?: string;    
    weightPounds?: string;
    isArt: boolean
}


export type NewItem = {
    id?: string
    name: string
    valuePerItem: number|undefined
    quantity: number|undefined
    weightPounds?: string
    description: string
    cubicFeet: string
    category: Category["id"]
    isArt: boolean
};


export interface CategoryWithInventory {
    name: Category["name"];
    id: Category["id"];
    detail?: Category["detail"];
    isDisabled: Category["isDisabled"]
    items: Item[]
}

export interface Coverage {
    programData?: {
        name: string;
        types: {
            declaration: string;
        };
        packDate: string;
    };
    program?: {
        name: string;
        types: {
            declaration: string;
        };
        packDate: string;
    };
    salesStage: string;
    effectiveDate: string;
    readOnlyDate: string;
    completedInventory?: boolean;
    shipperName: string;
    coverageLimit: number;
    id: string;
    friendlyId: string;
}

export enum ClosedSalesStage {
    purchased = "Purchased",
    canceled = "Canceled",
    duplicate = "Duplicate"
}

export enum OpportunityStatusDependency {
    afterPurchased = "AfterPurchase",
    beforePurchased = "BeforePurchase"
}

export enum DeclarationType {
    entireShipment = "Entire Shipment",
    specificItems = "Specific Items"
}

export enum CategoryId {
    highValue = "HIGH_VALUE",
    ownList = "OWN_LIST"
}

export interface ProviderSetting {
    active?: boolean;
    id?: string;
    opportunityStatusDependency?: OpportunityStatusDependency;
    sourceExternalCode?: string;
    coverageLimitLocked: boolean
}

export enum DeviceMinSize {
    desktop = 1024,
}

export enum LimitItemPrice {
    warningAmount = 50000,
    maxAmount = 100000,
}