import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { CustomModal } from './CustomModal';
import { makeStyles } from '@mui/styles';
import Trash from '../../Trash_Full.svg';
import { LoadingButton } from './LoadingButton';
import { Response } from 'superagent';
import swal from 'sweetalert';

import strings  from '../../config/strings';
const { modals : { delete : { successfulAlert, buttons : buttonsStr } } } = strings.UI;

const useStyles = makeStyles(() => {
    return {
        disclamer: {
            marginTop: '40px',
        },
        button: {
            margin: '40px',
        },
        img: {
            width: '100%',
        },
    };
});

interface Props {
    isOpen: boolean;
    showModal: React.Dispatch<React.SetStateAction<boolean>>;
    deleteDescription: string;
    deleteTitle: string;
    deleteAction: () => Promise<Response | undefined>;
}


export const DeleteModal = ({ isOpen, showModal, deleteDescription, deleteTitle, deleteAction }: Props) => {
    const classes = useStyles();
    const [isModalDisabled, setIsModalDisabled] = useState(false);
    
    const handleDelete = (e : React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsModalDisabled(true);
        deleteAction().then((r) => {
            if (r){
                if(localStorage.getItem('dismissSuccessfulOp') == 'true') {
                    window.location.reload()
                }else {
                    setTimeout(() => window.location.reload(), 7500);
                    swal({
                        title: successfulAlert.title,
                        text: successfulAlert.description,
                        icon: "success",
                        buttons: {
                            cancel: {
                            text: "Dismiss these messages",
                            value: false,
                            visible: true,
                            className: "btn-cancel",
                            closeModal: true
                            },
                            confirm: {
                            text: "OK",
                            value: true,
                            visible: true,
                            className: "btn-confirm",
                            closeModal: true
                            }
                        }
                    }).then((value) => {
                        if(!value){
                            localStorage.setItem('dismissSuccessfulOp', 'true')
                        }
                        window.location.reload();
                    })
                }
                setIsModalDisabled(false);
                showModal(false);
            }
        })
    }

    const handleCancel = () => {
        showModal(false);
    }

    return (
        <CustomModal isOpen={isOpen} onClose={handleCancel}>
            <form>
            <Box display="flex" justifyContent="space-around">
                <Box
                    width="60px"
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                    padding="10px"
                    borderRadius="50%"
                    bgcolor="#8EC1E3"
                    marginBottom="16px"
                >
                    <img src={Trash} className={classes.img} />
                </Box>
            </Box>
            <Typography
                marginBottom="20px"
                align="center"
                fontWeight="bold"
                id="modal-modal-title"
                variant="h3"
                component="h2"
            >
                { deleteTitle }
            </Typography>
            <Typography
                lineHeight="26px"
                align="center"
                fontWeight="normal"
                variant="caption"
                component="h2"
                sx={{ whiteSpace: 'pre-line', color: '#5A6A7F', fontSize: '20px' }}
            >
                {deleteDescription}
            </Typography>
            <Typography marginTop="40px" align="right">
                <Button
                    disabled={isModalDisabled}
                    sx={{ marginRight: '16px' }}
                    className={classes.button}
                    color="primary"
                    variant="text"
                    onClick={handleCancel}
                >
                    {buttonsStr.cancel}
                </Button>
                <LoadingButton
                    loading={isModalDisabled}
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    onClick={handleDelete}
                    type="submit"
                    autoFocus
                    >
                    {buttonsStr.confirm}
                </LoadingButton>
            </Typography>
            </form>
        </CustomModal>
    );
};
