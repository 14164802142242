import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { OptionalPinModal } from '../components/modals/OptionalPinModal';
import { getToken } from '../services/authService';
import { InformativeModal } from '../components/modals/InformativeModal';
import { LoadingModal } from '../components/modals/LoadingModal';
import { AddPinModal } from '../components/modals/AddPinModal';
import { useSelector } from 'react-redux';
import { CombinedReducersType } from '../store/modalTypes';

const forbiddenBody = 'Token not valid';

export const Landing = () => {
    const [loadingModal, setLoadingModal] = useState(true);
    const [showOptionalPinModal, setShowOptionalPinModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const { authReducer, modalReducer } = useSelector((state: CombinedReducersType) => state);
    const { pin } = authReducer;
    const { isAddPinModalOpen } = modalReducer;

    const navigate = useNavigate();

    useEffect(() => {
        //Public token from the URL
        const public_token = searchParams.get('public_token');
        //If the token is not the same as the last one i used, clean the storage
        public_token != localStorage.getItem('public_token') && localStorage.clear();

        if (public_token) {
            localStorage.setItem('public_token', public_token);
            getToken(public_token, pin)
                .then((res) => {
                    localStorage.setItem('private_token', res?.body.token);
                    //If this is the first time you are logging, you are given the option to enter a pin
                    if (!res?.body.firstUsedAt) {
                        // setShowOptionalPinModal(true);
                        setLoadingModal(false);
                        localStorage.setItem('first_time', 'true');
                        navigate('/');
                    }
                    //If its not the first time, you have authorization to view the page
                    else {
                        setLoadingModal(false);
                        localStorage.setItem('first_time', 'false');
                        navigate('/');
                    }
                })
                .catch((err) => {
                    if (err.response.statusCode == 403) {
                        setErrorModal(true);
                        setLoadingModal(false);
                    }
                    if (err.response.statusCode == 401) {
                        navigate('/login');
                    }
                });
        } else {
            setErrorModal(true);
        }
    }, []);

    return (
        <Container>
            <LoadingModal isOpen={loadingModal} />
            {/* <OptionalPinModal isOpen={showOptionalPinModal} /> */}
            <AddPinModal isOpen={isAddPinModalOpen} />
            <InformativeModal isOpen={errorModal} title={'Error'} body={forbiddenBody} />
        </Container>
    );
};
