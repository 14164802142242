import React, { useEffect, useState } from 'react';
import { getCategories, getInventory } from '../services/inventoryService';
import { Loading } from '../components/Loading';
import { CategoriesTable } from '../components/CategoriesTable';
import { AddItemModal } from '../components/modals/AddItemModal';
import { WelcomeModal } from '../components/modals/WelcomeModal';
import { DeclarationTypeModal } from '../components/modals/DeclarationTypeModal';
import { SpecificItemInfoModal } from '../components/modals/SpecificItemInfoModal';
import { EntireShipmentInfoModal } from '../components/modals/EntireShipmentInfoModal';
import { ChangePinModal } from '../components/modals/ChangePinModal';
import { InsuranceTermsModal } from '../components/modals/InsuranceTermsModal';
import { useDispatch, useSelector } from 'react-redux';
import { CombinedReducersType } from '../store/modalTypes';
import { useNavigate } from 'react-router-dom';
import { DeleteItemsModal } from '../components/modals/DeleteAllItemsModal';
import { EditItemModal } from '../components/modals/EditItemModal';
import { openDeclarationModal } from '../store/modalActions';
import { setCoverageLimitLocked, setDeclarationType, setProgramName, setProviderSettingsAction, setReadOnly } from '../store/authActions';
import { AddPinModal } from '../components/modals/AddPinModal';
import { getToken } from '../services/authService';
import { Category, Item, CategoryWithInventory, NewItem, Coverage, ProviderSetting, OpportunityStatusDependency, CategoryId, DeclarationType, ClosedSalesStage } from '../types';
import { EffectiveMessage } from '../components/EffectiveMessage';
import { Box, Typography } from '@mui/material';
import { getInventoryStatus } from '../services/inventoryService';
import { setInventoryStatus } from '../store/inventoryActions';
import { ConfirmInventory } from '../components/modals/ConfirmInventory';
import { Terms } from '../components/Terms';
import strings from '../config/strings'
interface Props {
    setCoverage: React.Dispatch<React.SetStateAction<Coverage | null>>;
    setProviderSettinglayout: React.Dispatch<React.SetStateAction<ProviderSetting | null>>;
}

export const GeneralView = ({setCoverage, setProviderSettinglayout}: Props) => {
    const [inventory, setInventory] = useState<Item[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [coverageData, setCoverageData] = useState<Coverage | null>(null);
    const [providerSetting, setProviderSetting] = useState<ProviderSetting | null>(null);
    const [showAddItem, setShowAddItem] = useState(false);
    const [showEditItem, setShowEditItem] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [initItem, setInitItem] = useState<NewItem | null>(null);
    const [showWelcomeModal, setShowWelcomeModal] = useState(false);
    const [loadingInventory, setLoadingInventory] = useState(false);
    const [loadingCategories, setLoadingCategories] = useState(false);
    const [showEntireShipmentInfoModal, setShowEntireShipmentInfoModal] = useState(false);
    const [showSpecificItemsInfoModal, setShowSpecificItemsInfoModal] = useState(false);
    const [showInsuranceTermsModal, setShowInsuranceTermsModal] = useState(false);
    const [pastEffectiveDateError, setPastEffectiveDateError] = useState('');
    const [isRowOpen, setIsRowOpen] = useState(false);
    const [tac, setTac] = useState(null);
    const { modalReducer, authReducer } = useSelector((state: CombinedReducersType) => state);
    const { isDeclarationModalOpen, isChangePinModalOpen, isAddPinModalOpen, isInventoryCompletedModalOpen } = modalReducer;
    const { pin } = authReducer;
    const [showEffectiveMessage, setShowEffectiveMessage] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isNotReadOnly = (coverageData: Coverage | null, providerSetting: ProviderSetting | null) => {
        if (!coverageData) return false;
        if (Object.values(ClosedSalesStage).includes(coverageData.salesStage as ClosedSalesStage) ) {
            if(!providerSetting || providerSetting?.opportunityStatusDependency == OpportunityStatusDependency.beforePurchased) return false;
            return true;
        }
        if(providerSetting?.opportunityStatusDependency == OpportunityStatusDependency.afterPurchased && !Object.values(ClosedSalesStage).includes(coverageData.salesStage as ClosedSalesStage)){
            return false;
        }
        const todayDate = new Date();
        
        if (coverageData.readOnlyDate){
            const readOnlyDate = new Date(coverageData.readOnlyDate);
            if (todayDate > readOnlyDate) {
                return false;
            }
        } else {
            return false;
        }

        return true;
    };

    useEffect(() => {
        if (coverageData) {
            const programData = coverageData.programData;
            if (localStorage.getItem('first_time') == 'true' && programData?.name === 'Moving Insurance') {
                dispatch(openDeclarationModal());
            }
            const declaration = coverageData.program?.types.declaration
            setCategoryStatus(categories, declaration)
            setCoverage(coverageData);
            setProviderSettinglayout(providerSetting);
            dispatch(setProviderSettingsAction(providerSetting));
            dispatch(setProgramName(programData?.name));
            dispatch(setDeclarationType(declaration));
            dispatch(setReadOnly(!isNotReadOnly(coverageData, providerSetting)));
            dispatch(setCoverageLimitLocked(providerSetting?.coverageLimitLocked || false));
        }
    }, [coverageData, providerSetting, categories]);

    useEffect(() => {
        let cancelSetState = false;
        setLoadingInventory(true);
        setLoadingCategories(true);
        const public_token = localStorage.getItem('public_token');
        getToken(public_token, pin)
            .then((res) => {
                localStorage.setItem('private_token', res?.body.token);
            })
            .catch((err) => {
                if (err.response && err.response.statusCode == 401) {
                    navigate('/login');
                }
            });
        getInventory(localStorage.getItem('private_token'))
            .then((res) => {
                if (!cancelSetState){
                    setInventory(res?.body.items);
                    setTac(res?.body.tac);
                    setLoadingInventory(false);
                }
            })
            .catch((err) => {
                if (err.response){
                    if (err.response.statusCode == 403) {
                        navigate(`/landing?public_token=${localStorage.getItem('public_token')}`);
                    }
                    if (err.response.statusCode == 404 && !cancelSetState) {
                        setLoadingInventory(false);
                    }
                }
            });
        getCategories(localStorage.getItem('private_token')).then((res) => {
            if (!cancelSetState){
                setCategories(res?.body.categories);
                setCoverageData(res?.body.coverage);
                setCoverage(res?.body.coverage)
                setProviderSetting(res?.body.provider_settings);
                setLoadingCategories(false);
            }
        }).catch(() => {
            console.log('error getting categories')
        });

        getInventoryStatus(localStorage.getItem('private_token')).then((res) => {
            if (!cancelSetState){
                const {inventoryCompleted} = res?.body
                dispatch(setInventoryStatus(inventoryCompleted));
                dispatch(setReadOnly(inventoryCompleted));
            }
        }).catch(() => {
            console.log('error getting inventory')
        });
        if (localStorage.getItem('first_time') == 'true') {
            setShowWelcomeModal(true);
        }

        return () => {
            cancelSetState = true;
        }
    }, []);

    const setCategoryStatus= (categories: Category[], declarationType: string | undefined) => {
        for (const category of categories) {
            const isDisabled = (
              (category.id === CategoryId.highValue && declarationType === DeclarationType.specificItems) ||
              (category.id === CategoryId.ownList && declarationType === DeclarationType.entireShipment)
            );
            category.isDisabled = isDisabled;
          }
          return categories;
    }   

    const mergeCategoriesWithInventory = (cat: Category[], inv: Item[]) : CategoryWithInventory[] => {
        const merged_categories: CategoryWithInventory[] = [];
        cat.map((category: Category, index: number) => {
            const filteredItems = inv.filter((item: Item) => {
                if (item.category === category.id) {
                    return item;
                }
            });
            merged_categories[index] = {
                name: category.name,
                id: category.id,
                detail: category.detail || '',
                isDisabled: category.isDisabled,
                items: filteredItems,
            };
        });
        return merged_categories;
    };

    return (
        <>
            {loadingCategories || loadingInventory ? (
                <Loading />
            ) : (
                <>
                    {showEffectiveMessage ? (
                    <>
                        <EffectiveMessage 
                            setEffectiveMessage={setShowEffectiveMessage}
                            readOnlyDate={coverageData && coverageData.readOnlyDate}
                            coverageData={coverageData}
                            providerSettings={providerSetting}
                            setPastEffectiveDateError={setPastEffectiveDateError}
                        />
                        <Box sx={{ m: 2 }}/>    
                    </>
                    ) : null}
                    <Box  sx={{ 
                            marginTop: {
                                xs: `${!isRowOpen ? '-5em' : '7em'}`,
                                sm: `${!isRowOpen ? '-0.5em' : '0px'}`, 
                                md: `${!isRowOpen ? '-5em' : '0px'}`,
                                lg: `${!isRowOpen ? '-10em' : '0px'}`,
                            },
                            '@media (max-width: 425px)': {
                                '@media(max-height: 800px)': {
                                    marginTop: `${!isRowOpen ? '-1em' : '4em'}`,
                                }
                            },
                            '@media (max-width: 380px)': {
                                marginTop: `${!isRowOpen ? '2em' : '5em'}`,
                                '@media(max-height: 700px)':{
                                    marginTop: `${!isRowOpen ? '10em' : '10em'}`
                                }
                            }
                        }}>
                        <Box sx={{ left: 0, marginBottom: '5rem', fontSize: '16px', color: '#808080' }}>
                            <Typography>
                                {strings.UI.layout.infoMessage.declarationTypeDetail}
                            </Typography>
                        </Box> 
                        <CategoriesTable
                            coverage={coverageData}
                            items={inventory}
                            categories={mergeCategoriesWithInventory(categories, inventory)}
                            setAddItem={setShowAddItem}
                            setShowEditItem={setShowEditItem}
                            setInitItem={setInitItem}
                            setShowInfoModal={setShowInfoModal}
                            pastEffectiveDateError={pastEffectiveDateError}
                            setIsRowOpen={setIsRowOpen}
                        />
                        <Terms setShowInsuranceTermsModal={setShowInsuranceTermsModal} tac={tac}/>
                    </Box>
                </>
            )}
            <EditItemModal
                showEditItem={showEditItem}
                setShowEditItem={setShowEditItem}
                categories={categories}
                initItem={initItem}
                coverage={coverageData}
                inventory={inventory}
            />
            <AddItemModal
                addItem={showAddItem}
                setAddItem={setShowAddItem}
                categories={categories}
                initItem={initItem}
                coverage={coverageData}
                inventory={inventory}
            />
            <WelcomeModal isOpen={showWelcomeModal} setShowWelcomeModal={setShowWelcomeModal} providerSettings={providerSetting}/>
            <DeclarationTypeModal
                isOpen={isDeclarationModalOpen}
                setShowEntireShipmentInfoModal={setShowEntireShipmentInfoModal}
                setShowSpecificItemsInfoModal={setShowSpecificItemsInfoModal}
            />
            <EntireShipmentInfoModal
                isOpen={showEntireShipmentInfoModal}
                setShowEntireShipmentInfoModal={setShowEntireShipmentInfoModal}
            />
            <SpecificItemInfoModal
                isOpen={showSpecificItemsInfoModal}
                setShowSpecificItemsInfoModal={setShowSpecificItemsInfoModal}
            />
            <ChangePinModal isOpen={isChangePinModalOpen} />
            <AddPinModal isOpen={isAddPinModalOpen} />
            <ConfirmInventory isOpen={isInventoryCompletedModalOpen}/>
            <DeleteItemsModal isOpen={showInfoModal} setShowInfoModal={setShowInfoModal} quantity={inventory.length} />
            <InsuranceTermsModal 
                isOpen={showInsuranceTermsModal}
                setShowInsuranceTermsModal={setShowInsuranceTermsModal}
            />
        </>
    );
};
